<template lang="pug">
component.mix-match__short(:is="link ? 'nuxt-link' : 'div'" :to="link || undefined")
  .mix-match__short-name-extra(v-if="shortNameExtra") {{ shortNameExtra }}
  .mix-match__short-name(v-if="shortName") {{ shortName }}
</template>

<script>
export default {
  name: 'MixMatchShort',
  props: {
    shortName: {
      type: String,
      default: null,
    },
    shortNameExtra: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.mix-match__short {
  position: relative;
  width: max-content;
  max-width: 100%;
  padding: 0 0 5px 0;

  &-name {
    position: relative;
    background: $fulvous;
    color: $white;
    padding: 4px 6px;
    box-sizing: border-box;
    border-radius: $radius-small;
    font-size: 14px;
    font-weight: bold;
    @include ellipses(24px);
  }

  &-name-extra {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 6px 6px 6px;
    margin: 0 0 -2px 0;
    border-style: solid;
    border-color: $fulvous;
    border-width: 1px 1px 0 1px;
    border-radius: $radius-small $radius-small 0 0;
    background: $white;
    color: $fulvous;
  }
}
</style>